import { ButtonGroup, ToggleButton, Badge } from "react-bootstrap";
import {useFiles, getAccumulatedTopics, getAccumulatedTopicData, useKeyedState, getOnlyTrue, useFile, Translation, generateMarks, getAccumulatedByTypes, getChannelGroups, useIdeologys, useSettings, useChannels as useChannelsCore} from "/src/utils";
import {memo, useCallback, Fragment, useEffect, useState, useMemo, useRef} from "react";
import {Info} from "/src/components";
import {Root} from "/src/network";
import {Topics, TimeSlider as TimeSlider2, TagStream, TopicsClickStream, TopicsClickBurst} from "/src/charts";


export const ItemPill = memo(({
  item,
  hovered = false,
  selected = false,
  toggleSelection,
  setHovered,
}) => {
  const {name, title, color} = item;
  const setTrue = useCallback(() => setHovered(true), [setHovered]);
  const setFalse = useCallback(() => setHovered(false), [setHovered]);

  const style =
    (hovered || selected) && color ? { backgroundColor: color } : undefined;

  return (
    <Badge
      pill
      className="Pointer"
      bg={hovered ? "hovered" : selected ? "primary" : "secondary"}
      style={style}
      onClick={toggleSelection}
      onMouseEnter={setTrue}
      onMouseOut={setFalse}
    >
      {name}
    </Badge>
  );
});


// Group data is static!
// isHovered is in the isHoveredList
// toggleSelected/toggleHovered need memoization
// and that toggle function can be written simpler!
export const ItemSelector = ({info, items, itemSelection, setItemSelection, itemHovered, setItemHovered, title, hideAllButton=false}) => {
  const toggleSelection = useMemo(() => items.map((item) => 
      () => setItemSelection(
        (itemSelection) => ({...itemSelection, [item.id]: !itemSelection[item.id]})
      )
  ), [items, setItemSelection]);
  const setHovered = useMemo(() => items.map((item) => 
    (value) => setItemHovered(
      (itemHovered) => ({...itemHovered, [item.id]: value})
    )
  ),
  [items, setItemHovered]);
  const selectedCount = useMemo(() => Object.entries(itemSelection).filter(([key, value]) => value).length, [itemSelection]);
  const selectAll = useCallback(() => setItemSelection({}), []);

  // Maybe use index everywhere?
  return <div className="Header Header--limitedHeight InfoOverlayed">
          <Info>
            <Translation k="info.selectionInfoText" />
          </Info>

    {!hideAllButton && <>
      <Badge
        pill
        className="Pointer"
        bg={
            selectedCount === 0
            ? "primary"
            : "secondary"
        }
        onClick={selectAll}
      >
        <Translation k="dashboard.allButton" />
      </Badge>
      <br />
        </>
    }
    <h5 className="Subcard__Title Title">
      <Translation k={title}/> {selectedCount === 0 ? `(${items.length})` : `(${selectedCount} / ${items.length})`}
    </h5>
    <Info>
      <Translation k="info.selectionInfoText"/>
    </Info>

    {items.map((item, index) => (
      <Fragment key={item.id}>
        <ItemPill
          item={item}
          selected={itemSelection[item.id]}
          toggleSelection={toggleSelection[index]}
          hovered={itemHovered[item.id]}
          setHovered={setHovered[index]}
          />{" "}
        </Fragment>
      ))}
      <div className="Subcard__Description Description">{info}</div>
  </div>
};

export const TimeSlider = ({dates, dateRange, setDateRange, setSelectedDateRange}) => {
  const settings = useSettings();
  const postCount = [];

  return <div className="Footer InfoOverlayed">
    <h4 className="Subcard__Title">
      <Translation k="dashboard.timeLine" />
      &nbsp; &nbsp;
      <Badge
        pill
        className="Pointer"
        bg={dateRange.all ? "primary" : "secondary"}
        onClick={() => {
          setDateRange([0, dates.length - 1]);
        }}
      >
        {!settings.hideFullTimeOverview && (
          <Translation k="dashboard.fullTimeOverview" />
        )}
      </Badge>
    </h4>
    <Info>
      <Translation k="info.timeLine" markdown />
    </Info>
    <TimeSlider2
      dates={dates}
      dateRange={dateRange}
      setDateRange={setDateRange}
      postCount={postCount}
    />
  </div>
}


export const Network = ({network, dateRange, selection, hoverSelection, channelSelection, setHoveredChannels, setSelectedChannels}) => {
  // This setting stuff is just to much!
  const settings = useSettings();

  return <div className="Footer NetworkMain InfoOverlayed">
    <h4 className="Subcard__Title">
      <Translation k="dashboard.networkTitle" />
    </h4>
    <Info>
      <Translation k="info.network" markdown />
    </Info>
    <div
      className="NetworkView"
      style={{ width: "100%", minHeight: "95%", height: "600px" }}
    >
      <Root
        selection={selection}
        hoverSelection={hoverSelection}
        channelSelection={channelSelection}
        setHoveredChannels={setHoveredChannels}
        setSelectedChannels={setSelectedChannels}
        network={network}
        date={dateRange.all ? "all" : dateRange.dateName}
        drawClusterLabels={settings.network.drawClusterLabels}
        zoomLevel={settings.network.zoomLevel}
        sizeMinimum={settings.network.sizeMinimum}
        sizeMultiplier={settings.network.sizeMultiplier}
        resizeNodes={settings.network.resizeNodes}
        edgeSizeMinimum={settings.network.edgeSizeMinimum}
        edgeSizeMultiplier={settings.network.edgeSizeMultiplier}
        nodeSizeAlgorithm={settings.network.nodeSizeAlgorithm}
        forceLayout={settings.forceLayout}
        mirkoAliasingFactor={settings.network.mirkoAliasingFactor}
        mirkoAliasingMaxDarken={
          settings.network.mirkoAliasingMaxDarken
        }
        mirkoAliasingEnabled={settings.network.mirkoAliasingEnabled}
      />
    </div>
  </div>
}

export const TopicButtonGroup = ({summaryData, topicType, setTopicType}) => {
  const clickHandler = useMemo(() => Object.fromEntries(Object.keys(summaryData).map((key) => [key, (e) => setTopicType(key)])), [setTopicType, summaryData]);

    return <ButtonGroup className="mb-2">
      {Object.entries(summaryData).map(([key, { label }]) => (
        <ToggleButton
          key={key}
          type="radio"
          variant="secondary"
          name={label}
          value={key}
          checked={topicType === key}
          onClick={clickHandler[key]}
        >
          <Translation k={`topicCategory-${label}`} />
        </ToggleButton>
      ))}
    </ButtonGroup>
};

export const PieChart = ({chartData, pieChartSelection, setPieChartSelection, loading}) => {
  const {topicType, level, selectedTopics} = pieChartSelection;
  const {topicEntries} = chartData;
  const settings = useSettings();

  const {setSelectedTopics, setLevel, setTopicType} = setPieChartSelection;

  // Theres multiple levels of sanity here
  // 1. We can select different data sources (i.e. different sets of channels and dateRanges)
  // 2. We can select a different chart type
  // 3. We can select topics and maybe subtopics to highlight.
  return <div className={"Header InfoOverlayed" + (loading ? " loading" : " loaded")}>
        <h4 className="Subcard__Title">
          <TopicButtonGroup summaryData={settings.summaryData} topicType={topicType} setTopicType={setTopicType}/>
        </h4>
        <Info>
          <Translation k="info.pieChart" markdown />
        </Info>
        <div className="Subcard__Content">
          {topicEntries.length > 0
              ? topicType === "Topics_per_month" ? (
                <TopicsClickBurst
                  limit={settings.topicLimit}
                  topics={topicEntries}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  level={level}
                  setLevel={setLevel}
                />
              ) : (
                <Topics
                  limit={settings.topicLimit}
                  topics={topicEntries}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  {...settings.summaryData[topicType]}
                />
              )
              : <div style={{color: "white", height: "300px"}}>Keine Daten</div>
            }
        </div>
    </div>
}

export const StreamChart = ({channels, chartData, pieChartData, pieChartSelection}) => {
  // TODO add loading indicator
  // TODO on long loads add loading error
  const settings = useSettings();
  const {startDate, endDate} = settings;

  const [channelData, loading] = useChannelData(channels);
  const {topics, topicEntries} = pieChartData;
  const {topicType, selectedTopics, level} = pieChartSelection;
  const topicOrder = useMemo( 
    () =>
      Object.entries(topics)
        .sort(([keyA, valueA], [keyB, valueB]) => valueB - valueA)
        .slice(0, settings.topicLimit)
        .map(([key, value]) => key),
    [topics, settings.topicLimit]
  );
  const setLevel = () => null;

  // TODO fix this bug better
  // basically this removes selections that can't be fullfilled anymore but it also causes a rerender
  const selectedTopicsDict = useMemo(
    () => getOnlyTrue(selectedTopics),
    [selectedTopics]
  );
  const topicData = useMemo(
    () =>
      getAccumulatedTopicData({
        channelData,
        key: topicType,
        startDate, endDate,
      }),
    [channelData, topicType, startDate, endDate]
  );

  useEffect(() => {
    let missingKeys = selectedTopicsDict.filter((topicKey) => topics[topicKey] === undefined);
    if(missingKeys.length > 0){
      setSelectedTopics(Object.fromEntries(missingKeys.map((key) => ([key, false]))));
    }

  }, [selectedTopicsDict, topics])


  return <div className={"Header InfoOverlayed" + (loading ? " loading" : " loaded")}>
      <h4 className="Subcard__Title">
        <Translation k="dashboard.topicTagStream" />
      </h4>
      <Info>
        <Translation k="info.stream" markdown />
      </Info>
      <div style={{ width: "100%", height: "300px", color: "black" }}>
        {topicOrder.length > 0
          ? topicType === "Topics_per_month"
            ? (
                <TopicsClickStream
                  limit={settings.topicLimit}
                  topicOrder={topicOrder}
                  data={topicData}
                  tag={selectedTopicsDict}
                  setSelectedTopics={() => null}
                  topics={topicEntries}
                  level={level}
                  setLevel={setLevel}
                  {...settings.summaryData[topicType]}
                />
              )
            : (
                <TagStream
                  limit={settings.topicLimit}
                  topicOrder={topicOrder}
                  data={topicData}
                  tag={selectedTopicsDict}
                  setSelectedTopics={() => null}
                  {...settings.summaryData[topicType]}
                />
            )
            : <div style={{color:"white"}}>Keine Daten für die Auswahl</div>
        }
      </div>
    </div>
}


export const useDates = () => {
  const interval = "months";
  const markFormat = "MMM.\u00A0YY'";
  const settings = useSettings();
  const {startDate, endDate} = settings;

  // TODO rename this stuff
  const marks = useMemo(
    () =>
      settings.timeSliderMarks ??
      generateMarks({ startDate, endDate, interval, format: markFormat }),
    [startDate, endDate, interval, markFormat, settings.timeSliderMarks]
  );
  return marks;
};


export const useDateRange = (dates) => {
  const [dateRange, setDateRangeInner] = useState([0, 0]);

  const setDateRange = useCallback((value) => {
    if(Array.isArray(value)){
      if(value.length == 1 || value[0] == value[1]){
        setDateRangeInner([value[0], value[0]]);
      }
      else if(value.length == 2){
        if(value[0] > value[1]){
          throw "Dates must be a valid interval";
        }
        setDateRangeInner(value);
      }
      else{
        throw "Dates must be a valid interval";
      }
    }
    else{
      setDateRangeInner([value, value]);
    }
  }, [setDateRangeInner]);

  const dateObject = useMemo(() => {
    const isDate = dateRange[0] === dateRange[1];
    const date = isDate ? dates[dateRange[0]] : null;

    return {
      start: dateRange[0],
      end: dateRange[1],
      value: isDate ? dateRange[0] : dateRange,
      range: isDate ? null : dateRange,
      rangeFormat: dateRange.map((date) => dates[date].format),
      rangeName: dateRange.map((date) => dates[date].name),
      date: isDate ? dateRange[0] : null,
      dateFormat: date?.format,
      dateName: date?.name,
      isRange: !isDate,
      length: dateRange[1] - dateRange[0],
      all: dateRange[0] === 0 && dateRange[1] === (dates.length - 1),
    };
  }, [dateRange]);

  return [dateObject, setDateRange];
};


export const useChannels = () => {
  const channels = useChannelsCore();
  const channelList = useMemo(
    () => Object.entries(channels)
    .map(([name, channel]) => ({id: "channel-"+name, name, ...channel}))
      .sort((a, b) => a.name.localeCompare(b.name)),
    [channels]
  );
  return channelList;
};


export const useGroups = (channels) => {
  // TODO cleanup the act.
  // TODO add title
  const settings = useSettings();
  const ideologys = useIdeologys();
  const groups = useMemo(
    () => ({
      ...getAccumulatedByTypes(channels, ideologys),
      ...settings.dashboard.groups,
    }),
    [channels, settings.dashboard.groups, ideologys]
  );
  const groupList = useMemo(
    () => Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)),
    [groups]
  );
  return groupList; 
};


export const useChannelGroupSelection = (groups, channels) => {
  const [selectedChannels, setSelectedChannels] = useState(() => (
    Object.fromEntries(channels.map((channel) => [channel.id, false]))
  ));
  const [selectedGroups, setSelectedGroups] = useState(() => (
    Object.fromEntries(groups.map((group) => [group.id, false]))
  ));
  const selection = useMemo(() => {
    const groupMembersByIndex = groups.filter((group) => selectedGroups[group.id]).reduce((a, group) => a.concat(group.members), []);
    // TODO weird that conversion
    const groupMembers = Object.fromEntries(groupMembersByIndex.map((index) => [channels[index].id, true]));
    return {...selectedChannels, ...groupMembers};
  }, [selectedGroups, selectedChannels]);

  return [selection, selectedChannels, selectedGroups, setSelectedChannels, setSelectedGroups];
}

export const useChannelGroupHover = (groups, channels) => {
  const [selectedChannels, setSelectedChannels] = useState(() => (
    Object.fromEntries(channels.map((channel) => [channel.id, false]))
  ));
  const [selectedGroups, setSelectedGroups] = useState(() => (
    Object.fromEntries(groups.map((group) => [group.id, false]))
  ));
  const selection = useMemo(() => {
    const groupMembersByIndex = groups.filter((group) => selectedGroups[group.id]).reduce((a, group) => a.concat(group.members), []);
    // TODO weird that conversion
    const groupMembers = Object.fromEntries(groupMembersByIndex.map((index) => [channels[index].id, true]));
    const channelGroups = channels.filter((channel) => selectedChannels[channel.id]).reduce((a, channel) => ({...a, ...Object.fromEntries(getChannelGroups(channel).map((group) => [group, true]))}), {});
    return {...selectedChannels, ...selectedGroups, ...groupMembers, ...channelGroups};
  }, [selectedGroups, selectedChannels]);

  return [selection, selectedChannels, selectedGroups, setSelectedChannels, setSelectedGroups];
}


export const useNetwork = (dateRange, selection) => null;
export const useNetworkState = () => [null, null];


export const useChannelData = (channels) => {
  const allFiles = useMemo(() => [["all", "data/__all_channels.json"]], []);
  const channelFiles = useMemo(() => Object.entries(channels).filter(([name, value]) => value).map(([name, value]) => [name, `data/${name.substring("channel-".length)}.json`]), [channels]);
  const [d, loading] = useFiles(channelFiles.length ? channelFiles : allFiles);
  const [data, setData] = useState({});

  useEffect(() => {
    // TODO this length thing is an awful way to fix the resetting of d
    if(!loading && Object.entries(d).length > 0){
      setData(d);
    }
  }, [d, loading]);

  return [data, loading];
};

export const usePieChartData = (pieChartSelection, channels, dateRange) => {
  const settings = useSettings();
  const [startDate, endDate] = dateRange.rangeName;

  console.log("sta", startDate, endDate);
  const [channelData, loading] = useChannelData(channels);
  // Contains the max amount per topic accumulated over all channels selected
  const topics = useMemo(() => getAccumulatedTopics({
      channelData,
      key: pieChartSelection.topicType,
      startDate,
      endDate,
    }),
    [channelData, pieChartSelection.topicType, startDate, endDate]
  );
  // Contains all topics
  const topicEntries = useMemo(() => Object.entries(topics), [topics]);

  const data = useMemo(() => ({
    topics,
    topicEntries
  }), [topics, topicEntries]);

  return [data, loading];
};


export const usePieChartSelection = () => {
  // Do the accumulation for each month
  const settings = useSettings();
  const [topicType, setTopicType] = useState(settings.defaultSummary);
  const [selectedTopics, setSelectedTopics] = useKeyedState(topicType, {});
  const [level, setLevel] = useKeyedState(topicType, []);

  const data = useMemo(() => ({
    topicType,
    selectedTopics,
    level
  }), [topicType, selectedTopics, level]);

  const handler = useMemo(() => ({
    setTopicType,
    setLevel,
    setSelectedTopics,
  }), [setTopicType, setLevel, setSelectedTopics]);

  return [data, handler];
};


export const useStreamChartData = (pieChartSelection) => null;

export const FullStateComp = () => {
  // Date selection
  const dates = useDates(); // Get dates from db.
  const [dateRange, setDateRange] = useDateRange(dates);

  // Channels and groups
  const channels = useChannels(); // db
  const groups = useGroups(channels); // db
  const [selection, channelSelection, groupSelection, setSelectedChannels, setSelectedGroups] = useChannelGroupSelection(groups, channels);

  const [hoverSelection, hoverChannelSelection, hoverGroupSelection, setHoveredChannels, setHoveredGroups] = useChannelGroupHover(groups, channels);

  // Network
  const network = useNetwork(dateRange, selection);
  // Maybe add the zoom stuff and so on here?
  const [networkState, setNetworkState] = useNetworkState();

  // Pie Chart stuff
  // What topic or subtopic is selected or hovered?
  // What chart type is selected
  const [pieChartSelection, setPieChartSelection] = usePieChartSelection();
  const [pieChartData, loadingPieChartData] = usePieChartData(pieChartSelection, selection, dateRange);

  // StreamChart
  const streamChartData = useStreamChartData(pieChartSelection);

  return (
    <div className="Content Content--smartphone">
      <div className="Dashboard">
        <ItemSelector 
          items={groups}
          itemSelection={groupSelection}
          setItemSelection={setSelectedGroups}
          itemHovered={hoverSelection}
          setItemHovered={setHoveredGroups}
          title="dashboard.groups"
        />
        <ItemSelector 
          items={channels}
          itemSelection={channelSelection}
          setItemSelection={setSelectedChannels}
          itemHovered={hoverSelection}
          setItemHovered={setHoveredChannels}
          title="dashboard.selectedNodes"
        />
        <div className="Main">
          <TimeSlider dates={dates} dateRange={dateRange} setDateRange={setDateRange}/>

          <div className="DashiMaxi">
            <Network
              network={network}
              dateRange={dateRange}
              setSelectedChannels={setSelectedChannels}
              setHoveredChannels={setHoveredChannels}
              selection={selection}
              hoverSelection={hoverSelection}
              channelSelection={channelSelection}
              networkState={networkState}
              setNetworkState={setNetworkState}
            />

            <div>
              <PieChart chartData={pieChartData} loading={loadingPieChartData} pieChartSelection={pieChartSelection} setPieChartSelection={setPieChartSelection}/>
              <StreamChart channels={selection} chartData={streamChartData} pieChartData={pieChartData} pieChartSelection={pieChartSelection} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
