import React, { memo, useEffect } from "react";
import { Badge } from "react-bootstrap";

export const GroupPill = memo(
  ({
    id,
    name,
    description,
    hovered = false,
    selected = false,
    children,
    setSelectedGroups,
    setHoveredGroup,
    color,
  }) => {
    useEffect(() => {
      // If this node is deleted while the group is currently hovered. Reset the hovered group
      return () =>
        setHoveredGroup((current) => (current === id ? null : current));
    }, [id, setHoveredGroup]);

    const style =
      (hovered || selected) && color ? { backgroundColor: color } : undefined;

    return (
      <Badge
        pill
        className="Pointer"
        bg={hovered ? "hovered" : selected ? "primary" : "secondary"}
        style={style}
        onClick={() => {
          setSelectedGroups?.((oldValue) =>
            oldValue.includes(id)
              ? oldValue.filter((item) => item !== id)
              : Array.from(new Set([...oldValue, id]))
          );
        }}
        onMouseEnter={() => setHoveredGroup(id)}
        onMouseLeave={() => setHoveredGroup(null)}
      >
        {children}
      </Badge>
    );
  }
);
