import React from "react";
import { GroupPill } from "/src/components";
import { useChannelInfos } from "/src/utils";

export const DetailTooltip = ({
  id,
  name,
  setSelectedGroups,
  hoveredGroup,
  setHoveredGroup,
  groups,
}) => {
  const channelData = useChannelInfos();
  return (
    <DetailView
      {...{
        ...channelData[name],
        id,
        name,
        setSelectedGroups,
        hoveredGroup,
        setHoveredGroup,
        groups,
      }}
    />
  );
};

const ignore = ["??", "--", "None", ""];

export const DetailView = ({
  name,
  Info,
  setSelectedGroups,
  hoveredGroup,
  setHoveredGroup,
  groups,
  ...props
}) => {
  const { Ideologie, Ideologie_grob } = Info;

  return (
    <div className="Header">
      <h3 className="Subcard__Title Title">{name}</h3>
      {!!Ideologie && !ignore.includes(Ideologie) && (
        <>
          <GroupPill
            {...groups[`ideology-${Ideologie}`]}
            id={`ideology-${Ideologie}`}
            hoveredGroup={hoveredGroup}
            setSelectedGroups={setSelectedGroups}
            setHoveredGroup={setHoveredGroup}
          >
            {Ideologie}
          </GroupPill>{" "}
        </>
      )}
      {!!Ideologie_grob && !ignore.includes(Ideologie_grob) && (
        <>
          <GroupPill
            {...groups[`organisation-${Ideologie_grob}`]}
            id={`organisation-${Ideologie_grob}`}
            hoveredGroup={hoveredGroup}
            setSelectedGroups={setSelectedGroups}
            setHoveredGroup={setHoveredGroup}
          >
            {Ideologie_grob}
          </GroupPill>{" "}
        </>
      )}
      {!Info["Chat"] && !ignore.includes(Info["Chat"]) && (
        <>
          <GroupPill
            {...groups["broadcast"]}
            id="broadcast"
            hoveredGroup={hoveredGroup}
            setSelectedGroups={setSelectedGroups}
            setHoveredGroup={setHoveredGroup}
          >
            Channel
          </GroupPill>{" "}
        </>
      )}
      {!!Info["Chat"] && !ignore.includes(Info["Chat"]) && (
        <>
          <GroupPill
            {...groups["megagroup"]}
            id="megagroup"
            hoveredGroup={hoveredGroup}
            setSelectedGroups={setSelectedGroups}
            setHoveredGroup={setHoveredGroup}
          >
            Chat
          </GroupPill>{" "}
        </>
      )}
      {!!Info["Sprache"] && !ignore.includes(Info["Sprache"]) && (
        <>
          <GroupPill
            {...groups[`language-${Info["Sprache"]}`]}
            id={`language-${Info["Sprache"]}`}
            hoveredGroup={hoveredGroup}
            setSelectedGroups={setSelectedGroups}
            setHoveredGroup={setHoveredGroup}
          >
            {Info["Sprache"]}
          </GroupPill>{" "}
        </>
      )}
      {!!Info["infos"] && !ignore.includes(Info["infos"]) && (
        <div className="Subcard__Description Description">{Info["infos"]}</div>
      )}
    </div>
  );
};
