import React, { memo, useMemo, Fragment, useState, useEffect } from "react";
import {timerHoc, timerWrap, useTimedMemo} from "/src/utils";
import {
  Topics,
  TopicsClickBurst,
  TopicsClickStream,
  TagStream,
  TimeSlider,
} from "/src/charts";
import { Root } from "/src/network";
import Badge from "react-bootstrap/Badge";
import {
  Translation,
  useIdeologys,
  useChannelInfos,
  useChannels,
  useSettings,
  getOnlyTrue,
  getAccumulatedTopics,
  getAccumulatedTopicData,
  getAccumulatedPostCount,
  getAccumulatedByTypes,
  useFile,
} from "/src/utils";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { Info, GroupPill, NodePill } from "/src/components";
import { DetailView } from "/src/containers";
import { useKeyedState } from "/src/utils";

const useTimeSlider = () => null;


const NodeSelection = ({
  allNodes,
  setSelectedNodes,
  setHoveredNode,
  setHoveredGroup,
  selectedNodesSet,
  groupSet,
  hoveredNode,
  setSelectedGroups,
}) => (
  <div className="Header Header--limitedHeight InfoOverlayed">
    <h5>
      <Translation k="dashboard.selectedNodes" /> ({allNodes.length}
      ):
    </h5>
    <Info>
      <Translation k="info.selections" markdown />
    </Info>
    {allNodes.map((name) => (
      <Fragment key={name}>
        <NodePill
          name={name}
          id={name}
          hovered={groupSet.has(name) || hoveredNode === name}
          selected={selectedNodesSet.has(name)}
          setHoveredNode={setHoveredNode}
          setSelectedNodes={setSelectedNodes}
          setHoveredGroup={setHoveredGroup}
          setSelectedGroups={setSelectedGroups}
        >
          {name}
        </NodePill>{" "}
      </Fragment>
    ))}
  </div>
);

const PillBox = 
  ({
    hideNodeSelection,
    allNodes,
    channelData,
    setSelectedNodes,
    setSelectedGroups,
    setHoveredAll,
    groups,
    hoveredAll,
    setHoveredGroup,
    hoveredNode,
    selectedGroups,
    settings,
    selectedNodes,
    realNodes,
    hoveredGroup,
    setHoveredNode,
    groupSet,
    selectedNodesSet,
  }) => {
    const groupList = useTimedMemo("pb_grouplist",
      () => Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)),
      [groups]
    );
    const selectedGroupsSet = useTimedMemo("pb_sgroupset",
      () => new Set(selectedGroups),
      [selectedGroups]
    );

    return (
      <>
        <div className="Header InfoOverlayed" style={{ position: "relative" }}>
          <Info>
            <Translation k="info.selectionInfoText" />
          </Info>

          <Badge
            pill
            className="Pointer"
            bg={
              hoveredAll || !!hoveredNode
                ? "hovered"
                : allNodes.length === Object.keys(channelData).length
                ? "primary"
                : "secondary"
            }
            onClick={() => {
              setSelectedNodes([]);
              setSelectedGroups([]);
            }}
            onMouseEnter={() => setHoveredAll(true)}
            onMouseLeave={() => setHoveredAll(false)}
          >
            <Translation k="dashboard.allButton" />
          </Badge>
          <br />
          <h5>
            <Translation k="dashboard.groups" />:
          </h5>
          {groupList.map(({ id, name, description, members, color }) => (
            <Fragment key={id}>
              <GroupPill
                id={id}
                name={name}
                hovered={
                  hoveredGroup?.id === id ||
                  (!!hoveredNode && members.includes(hoveredNode))
                }
                selected={selectedGroupsSet.has(id)}
                description={description}
                setHoveredGroup={setHoveredGroup}
                setSelectedGroups={setSelectedGroups}
                color={color}
              >
                {name} ({members.length})
              </GroupPill>{" "}
            </Fragment>
          ))}
          <br />
          {(settings.dashboard.actors.length > 0 ||
            (selectedNodes && selectedNodes.length > 0)) && (
            <>
              <Translation k="dashboard.actors" />:<br />
            </>
          )}
          {[...settings.dashboard.actors, ...realNodes].map((name) => (
            <Fragment key={name}>
              <NodePill
                id={name}
                name={name}
                hovered={groupSet.has(name) || hoveredNode === name}
                selected={selectedNodesSet.has(name)}
                setHoveredNode={setHoveredNode}
                setSelectedNodes={setSelectedNodes}
                setHoveredGroup={setHoveredGroup}
                setSelectedGroups={setSelectedGroups}
              >
                {name}
              </NodePill>{" "}
            </Fragment>
          ))}
        </div>
        {!hideNodeSelection &&
          (allNodes.length > 1 ||
            (selectedGroups.length > 0 && allNodes.length > 0)) && (
              <NodeSelection
              allNodes={allNodes}
              setSelectedNodes={setSelectedNodes}
              setHoveredNode={setHoveredNode}
              selectedNodesSet={selectedNodesSet}
              setHoveredGroup={setHoveredGroup}
              groupSet={groupSet}
              hoveredNode={hoveredNode}
              setSelectedGroups={setSelectedGroups}
              />
          )}
      </>
    );
  };

export const Smartphone = ({
  networkOnly = false,
  hideTimeSlider: paramHideTimeSlider,
  hideNodeSelection: paramHideNodeSelection,
  moveNodeSelectionToBottom: paramMoveNodeSelectionToBottom,
}) => {
  const settings = useSettings();
  const hideTimeSlider =
    paramHideTimeSlider ?? settings.hideTimeSlider ?? false;
  const hideNodeSelection =
    paramHideNodeSelection ?? settings.hideNodeSelection ?? false;
  const moveNodeSelectionToBottom =
    paramMoveNodeSelectionToBottom ??
    settings.moveNodeSelectionToBottom ??
    false;

  const channelData = useChannelInfos();
  const channelData2 = useChannels();
  const ideologys = useIdeologys();
  const groups = useTimedMemo("smartphone_groups",
    () => ({
      ...getAccumulatedByTypes(channelData2, ideologys),
      ...settings.dashboard.groups,
    }),
    [channelData2, settings.dashboard.groups, ideologys]
  );

  const { startDate, endDate } = settings;
  const {
    slider,
    setSlider,
    sliderDate,
    durationSlider,
    durationSliderDate,
    setDurationSlider,
    marks: months,
    duration,
  } = useTimeSlider({
    startDate,
    endDate,
    interval: settings.timeInterval,
  });
  const [timeFullRange, setTimeFullRange] = useState(false);

  // All nodes that are selected
  const [selectedNodes, setSelectedNodes] = useState([]);

  const realNode = selectedNodes.length === 1 ? selectedNodes[0] : null;
  const realNodes = selectedNodes;

  const nodeChannelData = !realNode || (channelData && channelData[realNode]);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [baseHoveredGroup, setHoveredGroup] = useState(null);
  const hoveredGroup = useTimedMemo("hovergroups",
    () => groups[baseHoveredGroup],
    [groups, baseHoveredGroup]
  );
  const [hoveredNode, setHoveredNode] = useState(null);

  let allNodes = useTimedMemo("allnodes", () => {
    let set = new Set();
    selectedGroups.forEach((id) =>
      groups[id].members.forEach((key) => set.add(key))
    );
    selectedNodes.forEach((id) => set.add(id));

    if (set.size > 0) {
      return Array.from(set).sort();
    }
    return Object.keys(channelData2).sort();
  }, [selectedGroups, selectedNodes, groups, channelData2]);

  const postCount = useTimedMemo("postcount",
    () => channelData ? getAccumulatedPostCount({
        channelData,
        channels: allNodes,
        startDate: settings.startDate,
        endDate: settings.endDate,
        months,
    }) : {},
    [channelData, allNodes, months, settings.startDate, settings.endDate]
  );

  const [hoveredAll, setHoveredAll] = useState(false);

  const groupSet = useTimedMemo("groupCount",
    () => (hoveredGroup ? new Set(hoveredGroup.members) : new Set()),
    [hoveredGroup]
  );
  const selectedNodesSet = useTimedMemo("selectedNode",
    () => new Set(selectedNodes),
    [selectedNodes]
  );

  return (
    <div className="Content Content--smartphone">
      <div className="Dashboard">
        {!networkOnly && (
          <PillBox
            hideNodeSelection={hideNodeSelection || moveNodeSelectionToBottom}
            allNodes={allNodes}
            channelData={channelData2}
            setSelectedNodes={setSelectedNodes}
            setSelectedGroups={setSelectedGroups}
            setHoveredAll={setHoveredAll}
            groups={groups}
            hoveredAll={hoveredAll}
            setHoveredGroup={setHoveredGroup}
            hoveredNode={hoveredNode}
            selectedGroups={selectedGroups}
            settings={settings}
            selectedNodes={selectedNodes}
            realNodes={realNodes}
            hoveredGroup={hoveredGroup}
            setHoveredNode={setHoveredNode}
            groupSet={groupSet}
            selectedNodesSet={selectedNodesSet}
          />
        )}
        <div className="Main">
          {!hideTimeSlider && (
            <div className="Footer InfoOverlayed">
              <h4 className="Subcard__Title">
                <Translation k="dashboard.timeLine" />
                &nbsp; &nbsp;
                <Badge
                  pill
                  className="Pointer"
                  bg={timeFullRange ? "primary" : "secondary"}
                  onClick={() => {
                    setTimeFullRange(true);
                    setDurationSlider([0, duration]);
                  }}
                >
                  {!settings.hideFullTimeOverview && (
                    <Translation k="dashboard.fullTimeOverview" />
                  )}
                </Badge>
              </h4>
              <Info>
                <Translation k="info.timeLine" markdown />
              </Info>
              <TimeSlider
                range={settings.useDurationSlider}
                timeFullRange={timeFullRange}
                setTimeFullRange={setTimeFullRange}
                showPostCount={settings.showPostCount}
                postCount={postCount}
                duration={duration}
                slider={
                  timeFullRange || settings.useDurationSlider
                    ? durationSlider
                    : slider
                }
                setSlider={
                  settings.useDurationSlider ? setDurationSlider : setSlider
                }
                startDate={startDate}
                endDate={endDate}
                interval={settings.timeInterval}
              />
            </div>
          )}
          <div className="DashiMaxi">
            <div className="Footer NetworkMain InfoOverlayed">
              <h4 className="Subcard__Title">
                <Translation k="dashboard.networkTitle" />
              </h4>
              <Info>
                <Translation k="info.network" markdown />
              </Info>
              <div
                className="NetworkView"
                style={{ width: "100%", minHeight: "95%", height: "600px" }}
              >
                <Root
                  hoveredGroup={hoveredGroup}
                  hoveredNode={hoveredNode}
                  setHoveredNode={setHoveredNode}
                  allNodes={allNodes}
                  selectedNodes={selectedNodes}
                  selectedGroups={selectedGroups}
                  setSelectedNodes={setSelectedNodes}
                  setSelectedGroups={setSelectedGroups}
                  date={timeFullRange ? "all" : sliderDate}
                  drawClusterLabels={settings.network.drawClusterLabels}
                  zoomLevel={settings.network.zoomLevel}
                  sizeMinimum={settings.network.sizeMinimum}
                  sizeMultiplier={settings.network.sizeMultiplier}
                  resizeNodes={settings.network.resizeNodes}
                  edgeSizeMinimum={settings.network.edgeSizeMinimum}
                  edgeSizeMultiplier={settings.network.edgeSizeMultiplier}
                  nodeSizeAlgorithm={settings.network.nodeSizeAlgorithm}
                  forceLayout={settings.forceLayout}
                  mirkoAliasingFactor={settings.network.mirkoAliasingFactor}
                  mirkoAliasingMaxDarken={
                    settings.network.mirkoAliasingMaxDarken
                  }
                  mirkoAliasingEnabled={settings.network.mirkoAliasingEnabled}
                />
              </div>
            </div>
            <div>
              {!networkOnly && (
                <>
                  {selectedGroups.length === 0 &&
                    !!nodeChannelData &&
                    selectedNodes.length === 1 &&
                    allNodes.length === 1 &&
                    !!realNode &&
                    !!realNode && (
                      <DetailView
                        name={realNode}
                        {...nodeChannelData}
                        setSelectedGroups={setSelectedGroups}
                        hoveredGroup={hoveredGroup}
                        setHoveredGroup={setHoveredGroup}
                        groups={groups}
                      />
                    )}
                  <GeneralDataView
                    channelData={channelData}
                    channels={allNodes}
                    generalStartDate={startDate}
                    generalEndDate={endDate}
                    startDate={
                      settings.useDurationSlider || timeFullRange
                        ? durationSliderDate[0]
                        : sliderDate
                    }
                    endDate={
                      settings.useDurationSlider || timeFullRange
                        ? durationSliderDate[1]
                        : sliderDate
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {!hideNodeSelection &&
          moveNodeSelectionToBottom &&
          (allNodes.length > 1 ||
            (selectedGroups.length > 0 && allNodes.length > 0)) && (
            <NodeSelection
              allNodes={allNodes}
              setSelectedNodes={setSelectedNodes}
              setHoveredNode={setHoveredNode}
              selectedNodesSet={selectedNodesSet}
              setHoveredGroup={setHoveredGroup}
              groupSet={groupSet}
              hoveredNode={hoveredNode}
              setSelectedGroups={setSelectedGroups}
            />
          )}
      </div>
    </div>
  );
};


/*
 Shows the pie and stream charts

 The pie chart is a slice of the stream chart at a specific date.

 And the accumulation is over all channels selected.

 So we need to precalculate (for faster loading):
 - All channels for all month
 - Maybe all groups?
 - Maybe all single channels?
*/
const GeneralDataView = ({
    channels,
    startDate,
    endDate,
    generalStartDate,
    generalEndDate,
  }) => {

    let fileName = "data/__all_channels.json";

    if(channels.length === 1){
      fileName = `data/${channels[0]}.json`;
    }
    const [d, error] = useFile(fileName);

    console.log(channels);
    channels = ["all"];
    const channelData = d ? {"all": d} : {};

    const settings = useSettings();
    const [topicType, setTopicType] = useState(settings.defaultSummary);
    // Contains the max amount per topic accumulated over all channels selected
    const topics = useTimedMemo("gdv_topics",
      () => getAccumulatedTopics({
        channelData,
        key: topicType,
        channels,
        startDate,
        endDate,
      }),
      [channelData, topicType, channels, startDate, endDate]
    );
    // Contains all topics
    const topicEntries = useTimedMemo("gdv_entries", () => Object.entries(topics), [topics]);

    const topicOrder = useTimedMemo("gdv_topicOrder", 
      () =>
        Object.entries(topics)
          .sort(([keyA, valueA], [keyB, valueB]) => valueB - valueA)
          .slice(0, settings.topicLimit)
          .map(([key, value]) => key),
      [topics, settings.topicLimit]
    );

    // Do the accumulation for each month
    const topicData = useTimedMemo("gdv_topicData",
      () =>
        getAccumulatedTopicData({
          channelData,
          key: topicType,
          channels,
          startDate: generalStartDate,
          endDate: generalEndDate,
        }),
      [channelData, topicType, channels, generalStartDate, generalEndDate]
    );
    const [selectedTopics, setSelectedTopics] = useKeyedState(topicType, {});
    const [level, setLevel] = useKeyedState(topicType, []);

    const selectedTopicsDict = useTimedMemo("gdv_onlyTrue",
      () => getOnlyTrue(selectedTopics),
      [selectedTopics]
    );

    // TODO fix this bug better
    // basically this removes selections that can't be fullfilled anymore but it also causes a rerender
    useEffect(() => {
      let missingKeys = selectedTopicsDict.filter((topicKey) => topics[topicKey] === undefined);
      if(missingKeys.length > 0){
        setSelectedTopics(Object.fromEntries(missingKeys.map((key) => ([key, false]))));
      }

    }, [selectedTopicsDict, topics])

    if(!d || error){
      return "Loading";
    }

    return (
      <>
        <div className="Header InfoOverlayed">
          <h4 className="Subcard__Title">
            <ButtonGroup className="mb-2">
              {Object.entries(settings.summaryData).map(([key, { label }]) => (
                <ToggleButton
                  key={key}
                  type="radio"
                  variant="secondary"
                  name={label}
                  value={key}
                  checked={topicType === key}
                  onClick={(e) => setTopicType(key)}
                >
                  <Translation k={`topicCategory-${label}`} />
                </ToggleButton>
              ))}
            </ButtonGroup>
          </h4>
          <Info>
            <Translation k="info.pieChart" markdown />
          </Info>
          <div className="Subcard__Content">
            {topicOrder.length > 0
              ? topicType === "Topics_per_month" ? (
                <TopicsClickBurst
                  limit={settings.topicLimit}
                  topicOrder={topicOrder}
                  topics={topicEntries}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  level={level}
                  setLevel={setLevel}
                />
              ) : (
                <Topics
                  limit={settings.topicLimit}
                  topicOrder={topicOrder}
                  topics={topicEntries}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  {...settings.summaryData[topicType]}
                />
              )
              : <div style={{color: "white"}}>Keine Daten</div>
            }
          </div>
        </div>
        <div className="Header InfoOverlayed">
          <h4 className="Subcard__Title">
            <Translation k="dashboard.topicTagStream" />
          </h4>
          <Info>
            <Translation k="info.stream" markdown />
          </Info>
          <div style={{ width: "100%", height: "300px", color: "black" }}>
            {topicOrder.length > 0
              ? topicType === "Topics_per_month"
                ? (
                    <TopicsClickStream
                      limit={settings.topicLimit}
                      topicOrder={topicOrder}
                      data={topicData}
                      tag={selectedTopicsDict}
                      setSelectedTopics={setSelectedTopics}
                      topics={topicEntries}
                      level={level}
                      setLevel={setLevel}
                      {...settings.summaryData[topicType]}
                    />
                  )
                : (
                    <TagStream
                      limit={settings.topicLimit}
                      topicOrder={topicOrder}
                      data={topicData}
                      tag={selectedTopicsDict}
                      setSelectedTopics={setSelectedTopics}
                      {...settings.summaryData[topicType]}
                    />
                )
                : <div style={{color:"white"}}>Keine Daten für die Auswahl</div>
            }
          </div>
        </div>
      </>
    );
  };
