import { useState, useCallback } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { Info } from "/src/components/";
import {useHashtagExplorer} from "./utils";
import "./style.scss";

const darkTheme = {
  "axis": {
      "legend": {
          "text": {
              "fill": "#FFFFFF",
          }
      },
      "ticks": {
          "text": {
              "fill": "#FFFFFF",
          }
      }
  },
  "legends": {
      "text": {
          "fill": "#FFFFFF",
      }
  },
}

export const HashtagExplorer = () => {
  const data = useHashtagExplorer();
  const [pointDotColor, setPointDotColor] = useState({});
  const [pointSerieId, setPointSerieId] = useState(null);
  const [pointDates, setPointDates] = useState("");
  const [pointVideoCount, setPointVideoCount] = useState("");
  const [pointHashtags, setPointHashtags] = useState([]);

  const onPoint = useCallback(
    (point, event) => {
      setPointDotColor({ backgroundColor: point.serieColor });
      setPointSerieId(point.serieId);
      const pointDatesLabel = `${point.data.dates}:`;
      setPointDates(pointDatesLabel);
      const videoCountLabel = `${point.data.y} Videos`;
      setPointVideoCount(videoCountLabel);
      setPointHashtags(point.data.hashtags);
    },
    [setPointDotColor, setPointSerieId, setPointDates, setPointVideoCount, setPointHashtags],
  );


  return (
    <div className="Content Content--hashtag-explorer">
      <div className="Dashboard">
        <div className="Main" style={{ flexDirection: "column" }}>
          <div className="Footer NetworkMain InfoOverlayed" style={{ flex: 3 }}>
            <h4 className="Subcard__Title">Videos auf TikTok zu #sylt, #lamourtoujours und dem dazugehörigen Song</h4>
            <Info>Zeitliche Darstellung der Videos, welche unter den Hashtags #sylt, #lamourtoujours, sowie unter Verwendung der Musik-ID 
              des Songs L'amour toujours von Gigi D'Agostino und weiteren Remixes, ab September 2023 bis Juli 2024 auf TikTok in Deutschland 
              veröffentlicht wurden. Zu beachten ist die logarithmische Skalierung der Videoanzahl. Die Datenpunkte fassen die Zahlen für
              jeweils 7 Tage zusammen. Weitere Informationen, insbesondere über zusätzlich verwendete Hashtags, werden beim Markieren
              der Datenpunkte angezeigt.</Info>
            <div style={{ width: "100%", height: "30vh" }}>
            <ResponsiveLine
              data={data}
              theme={darkTheme}
              onMouseMove={onPoint}
              tooltip={({ point }) => {
                return null;
              }} 
                margin={{ top: 30, right: 40, bottom: 30, left: 40 }}
              xFormat="time:%d.%m.%y"
              xScale={{
                format: '%d.%m.%y',
                precision: 'day',
                type: 'time',
                useUTC: false
              }}
              gridYValues={[10, 100, 1000, 10000, 100000]}
              yScale={{
                type: 'log',
                base: 10,
                min: 1,
                max: 100000,
              }}
              curve="monotoneX"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: '%d.%m.%y',
                tickRotation: 0,
                tickValues: 'every 1 month',
              }}
              axisLeft={{
                  tickValues: [10, 100, 1000, 10000, 100000],
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Videos',
                  legendOffset: -50,
                  legendPosition: 'middle'
              }}
              pointSize={12}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor', modifiers: [] }}
              enableTouchCrosshair={true}
              useMesh={true}
              legends={[
                  {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 70,
                      itemsSpacing: 70,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 10,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  }
              ]}
              markers={[
                {
                  axis: 'x',
                  legend: 'Pfingsten 2024',
                  lineStyle: {
                    stroke: '#b0413e',
                    strokeWidth: 1.0
                  },
                  textStyle: {
                    fill: '#FFFFFF',
                  },
                  value: new Date(2024, 4, 20)
                },
                {
                  axis: 'x',
                  legend: 'Bergholz',
                  lineStyle: {
                    stroke: '#b0413e',
                    strokeWidth: 1.0
                  },
                  textStyle: {
                    fill: '#FFFFFF',
                  },
                  value: new Date(2023, 9, 14)
                },
              ]}
            />
            </div>
          </div>
          <div className="Footer InfoOverlayed" style={{ flex: 0 }}>
            <div className="hashtag-details-data">
              <h4 className="Subcard__Title">
                <span
                  className="hashtag-dot"
                  style={pointDotColor}
                ></span>{" "}
                {pointSerieId}
                {" -  "}
                  <b>
                    {pointDates}
                  </b>{" "}
                  {pointVideoCount}
              </h4>
              <Info>Hier erfolgt die Auflistung der zusätzliche verwendeten Hashtags und deren Anzahl für den 
                ausgewählten Datenpunkt. Herausgefiltert wurden im Vorfeld algorithmische Hashtags. Beispielhaft 
                seien hier einige genannt: #fyp, #foryou, #viral, #foryoupage, #parati, #tiktok, #capcut, 
                #trending, #funny, #viraltiktok
              </Info>
            </div>
            <div
              className="hashtag-details-chart"
              style={{ width: "100%", height:"30vh" }}
            >
              <ResponsiveBar
                layout="vertical"
                theme={darkTheme}
                margin={{ top: 10, right: 40, bottom: 75, left: 40 }}
                data={pointHashtags}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 15,
                  tickRotation: 0,
                  legendOffset: 32,
                  truncateTickAt: 0,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 15,
                  tickRotation: -40,
                  legendOffset: 32,
                  truncateTickAt: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
