import dayjs from "dayjs";


const getFormattedDate = (date, format) => {
  if (format) {
    return date.format(format);
  } else if (withDay) {
    return date.format("YYYY-MM-DD");
  } else {
    return date.format("YYYY-MM");
  }
}


export const generateMarks = ({
  startDate,
  endDate,
  withDay = false,
  format = null,
  interval = "month",
}) => {
  let cur = dayjs(startDate);
  let result = [];
  let index = 0;

  while (true) {
    result.push({
      index,
      format: getFormattedDate(cur, format),
      name: cur.format("YYYY-MM"), 
    });

    cur = cur.add(1, interval);
    index += 1;
    if (!cur.isBefore(endDate)) {
      break;
    }
  }
  return result;
};

