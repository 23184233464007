import React, { memo, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { toggleArrayValues } from "/src/utils";

export const NodePill = memo(
  ({
    id,
    name,
    hovered = false,
    selected = false,
    setSelectedNodes,
    setHoveredNode,
    hoveredGroup,
    setHoveredGroup,
    setSelectedGroups,
  }) => {
    useEffect(() => {
      // If this node is deleted while the group is currently hovered. Reset the hovered group
      return () =>
        setHoveredNode((current) => (current === name ? null : current));
    }, [name, setHoveredNode]);

    return (
      <Badge
        pill
        className="Pointer"
        bg={hovered ? "hovered" : selected ? "primary" : "secondary"}
        onClick={() =>
          setSelectedNodes((nodes) => toggleArrayValues(nodes, id))
        }
        onMouseEnter={() => setHoveredNode(name)}
        onMouseLeave={() => setHoveredNode(null)}
      >
        {name}
      </Badge>
    );
  }
);
